.filter_chat::before {
  display: table;
  content: "";
}

.filter_chat::after {
  display: table;
  clear: both;
  content: "";
}

.filter_chat > li {
  width: 19.9333%;
  float: left;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: end;
}

.table-scroll {
  overflow-x: scroll;
  background-color: white;
}

.reverse {
  display: flex;
  flex-direction: column-reverse;
}
.chat-scroll {
  overflow-y: scroll !important;
  height: 400px;
  overflow-x: hidden;
}

/* .filter_chat > li:not(:last-child) {
} */

.filter_chat {
  border-top: none;
  width: 50%;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-right: -362px;
  margin-top: -44px;
}

.chat_title {
  height: 100%;
  display: flex;
  align-items: center;
}

.filter_buttons_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.chat_list_container {
  background: rgb(248, 251, 254);
  padding: 30px 64px;
}

.chatheaderMenu {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.chatheaderMenu > li {
  float: left;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
}

.chatheaderMenu > li:not(:last-child) {
  border-right: none;
}

li.bellNotification {
  display: flex;
  align-self: center;
}

.headerPorifileDiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.buttonOutline {
  color: #4872b6;
  border-color: #0252a1;
}

.headerSubtitle {
  font-size: 17px;
  font-weight: 500;
}
.provider-name {
  display: flex;
}
.proifle_name {
  font-size: 14px;
  font-weight: 400;
  color: black;
  margin-left: 10px;
}

.chatItem_avatar {
  border-radius: 30%;
  margin-right: 10px;
}

.flexcenter {
  display: flex;
  align-items: center;
}

.taglinegray {
  color: #c9c9c9;
}

.chatitem > a {
  font-size: 14px;
  text-transform: capitalize;
  color: black;
}

.chatitem_name {
  font-size: 14px;
  text-transform: capitalize;
  color: black !important;
}

li.profile_button {
  padding: 0px 20px;
}

.leftSidebar {
  box-shadow: -7px 0px 58px 2px #00000024;
  z-index: 1;
}

.SideMenu > ul:hover {
  width: 100%;
}

.footer-btn {
  text-align: center;
  text-transform: uppercase;
  color: #fff !important;
  background: #2d64af;
  border-radius: 10px;
  height: 100%;
  /* width: 88%; */
  padding: 2% 5%;
  cursor: pointer;
  display: inline-block;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  /* margin: 20px 0;
  margin-bottom: 20px; */
  /* margin-left: 15rem; */
  margin-top: 5%;
  text-align: center;
  border-radius: 4px;
}

.loaderGenerateLink {
  /* margin: 20px 0;
  margin-bottom: 20px; */
  /* margin-left: 15rem; */
  margin-top: 5%;
  text-align: center;
  border-radius: 4px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1024px) {
  .filter_chat {
    width: 70%;
  }
  .table-scroll {
    overflow-x: scroll;
    background-color: white;
  }
}
