
.dashboardheaderMenu {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: none;
    margin: 0;
    padding: 0;
    list-style: none;
}

.dashboardheaderMenu>li {
    float: left;
    margin: 12px 0;
    color: rgba(0, 0, 0, .45);
}

.dashboardheaderMenu>li:not(:last-child) {
    border-right: none
}

li.bellNotification {
    display: flex;
    align-self: center;
}

.headerPorifileDiv {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.buttonOutline {
    color: #4872b6;
    border-color: #0252a1;
}

.headerSubtitle {
    font-size: 17px;
    font-weight: 500;
}

.proifle_name {
    font-size: 14px;
    font-weight: 400;
    color: black;
    margin-left: 10px;
}

.chatItem_avatar {
    border-radius: 30%;
    margin-right: 10px;
}

.flexcenter {
    background-color: transparent;
    display: flex;
    align-items: center;
}

.head {
    padding-top: 30px;
}

.Heading {
    width: auto;
    background: white;
    padding: 25px;
    border-radius: 15px;
    float: left;
}

.total {
    color: black;
    font-size: 15px;
    font-weight: 500;
    text-align: left;

}

.Users {
    color: #2d64ad;
    text-align: left;
    font-size: 25px;
    font-weight: 600;
}

.user {
    padding-left: 40px;
    margin-top: -30px;
}

.userHeading {
    width: auto;
    background: white;
    padding: 25px;
    border-radius: 15px;
    margin-left: 25px;
}

.patient {
    margin-left: 25px;
}

.doctor {
    margin-left: 25px;
}

.earning {
    margin-left: 25px;
}

@media (max-width: 736px) {
    .heading-font {
        font-size: 16px !important;
    }
    .Heading {
        margin-left: -6px;
    }
    .patient {
        margin-top: 20px;
    }
    .doctor {
        margin-top: 20px;
        padding-right: 48px;
    }
    .earning {
        margin-top: 20px;
    }
}