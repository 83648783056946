.staff_modal {
  width: 90vw !important;
}

.staff_modal .ant-modal-content {
  width: 80%;
  margin: auto;
}

#staff_modal {
  display: none;
}

.drag-handle {
  cursor: move !important;
}

.staff_modal_wrap {
  overflow: hidden;
  top: -80px;
}

.config_modal {
  width: 90vw !important;
}

.config_modal .ant-modal-content {
  width: 80%;
  margin: auto;
}

.show {
}

.hide {
  opacity: 0.5;
}

.field-allowed {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 66px;
}
