/* .filter_chat::before {
    display: table;
    content: '';
}

.filter_chat::after {
    display: table;
    clear: both;
    content: '';
}

.filter_chat>li {
    width: 23.3333%;
    float: left;
    margin: 12px 0;
    color: rgba(0, 0, 0, .45);
    text-align: end;
}

.filter_chat>li:not(:last-child) {}

.filter_chat {
    border-top: none;
    width: 200px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.chat_title {
    height: 100%;
    display: flex;
    align-items: center;
}

.filter_buttons_container {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.chat_list_container {
    background: rgb(248, 251, 254);
    padding: 30px 64px;
}

.chatheaderMenu {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: none;
    margin: 0;
    padding: 0;
    list-style: none;
}

.chatheaderMenu>li {
    float: left;
    margin: 12px 0;
    color: rgba(0, 0, 0, .45);
}

.chatheaderMenu>li:not(:last-child) {
    border-right: none
}

li.bellNotification {
    display: flex;
    align-self: center;
}

.headerPorifileDiv {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.buttonOutline {
    color: #4872b6;
    border-color: #0252a1;
}

.headerSubtitle {
    font-size: 17px;
    font-weight: 500;
}



.chatItem_avatar {
    border-radius: 30%;
}

.flexcenter {
    display: flex;
    align-items: center;
}

.taglinegray {
    color: #c9c9c9
}

.chatitem>a {
    font-size: 14px;
    text-transform: capitalize;
    color: black;
}

.chatitem_name {
    font-size: 14px;
    text-transform: capitalize;
    color: black !important;
}

li.profile_button {
    padding: 0px 20px;
}

.leftSidebar {
    box-shadow: -7px 0px 58px 2px #00000024;
    z-index: 1;
} */

/* 
    ##
    ##
    ##
    
    Code for speciality
    
    ##  
    ##
    ##
*/

.proifle_name {
    font-weight: bold;
}

.modal_speciality {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
}

.addSpeciality_button {
    margin: 10px
}

.ErrorInputClass {
    border-color: red !important;
}

@media (max-width: 580px) {
    .proifle_name {
        display: none;
    }

    .ant-modal {
        padding-bottom: 0px !important;
    }
}

.ant-modal-title {
    font-weight: bold !important;
}