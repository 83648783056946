/* @tailwind base;
@tailwind components;
@tailwind utilities; */

.wrapper{
    position: relative;
    min-height: 120px;
}
.dob_grid {
    display: flex;
    justify-content: space-between;
}
.dob_grid .ant-form-item{
    width: 30%;
}

.view_form {
    display        : flex;
    align-items    : center;
    justify-content: flex-start;
    width          : 30%;
}

.org_view_submit{
    display: flex;
    align-self: center;
}

.new_button{
    position: absolute;
    right: 0;
    margin-top: 50px;
 }

 #viewStaff .org_select .ant-form-item-label{
     display: none;
 }

 .left{
     text-align: left;
     left: 0;
 }