@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}



.header-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  overflow: hidden;

}

.header-bell {
  margin-right: 30px;
}

.header-user {
  margin-right: 30px;
}

#button-logout {
  background-color: #0052a1 !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  color: white !important;
  border-radius: 13px !important;
}

/* .ant-btn-background-ghost {
  border-color:#0052a1 !important;
} */

#button-logout:hover {
  background-color: #24a0ed !important;
  color: white !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.headers {
  background-color: transparent !important;
  /* height: 5%; */
}

.App-link {
  color: #09d3ac;
}

.MainMenu>li {
  font-weight: 500;
  letter-spacing: 1px;
}

.menu-is-active {
  font-weight: 700;
  letter-spacing: 1.4px;
}

#side-menu1:hover {
  background: blue;
}

#side-menu2:hover {
  background: blue;
  color: white;
}

#side-menu3:hover {
  background: blue;
}

#side-menu4:hover {
  background: blue;
}

#font-colour1:hover {
  color: white;
}

#font-colour3:hover {
  color: white;
}

#font-colour4:hover {
  color: white;
}

.leftSidebar {
  box-shadow: -7px 0px 58px 2px #00000024;
  z-index: 1;
  background: white;
}

.mainContent {
  min-height: 100vh !important;
}

.ant-list-item-meta-content {
  text-align: left;
}

.ant-layout-content {
  margin: 24px 16px 0px;
  overflow: initial;
  background: #f8fbff;
}

.login-logo-sec {
  background: #2D64AF;
  width: 45% !important;
  margin-top: 20%;
}

.signedin-logo-sec {
  background: white;
  width: 30% !important;
  margin: 3% 0% 5%;
}

.loading {
  text-align: center;
  margin-top: 150px;
}

@media (max-width: 840px) {
  .SideMenu {
    font-size: 12px;
  }
}