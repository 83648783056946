/* Add Org Starts  */
/* .ant-row{
  display: block !important;
} */
h3.heading-font {
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0.8px;
  display: flex;
}

.btn-type12 {
  display: flex;
  margin-left: -248px;
  border-radius: 13px;
  font-weight: 900;
  background-color: #0052a1;
  color: white;
  font-size: 16px;
  padding: 10px 20px 32px 20px;
}

.button-spacing {
  margin: 1%;
}

.ant-modal-confirm-body {
  height: auto !important;
}

.row-2 {
  margin: 2%;
  /* display:flex; */
}

.comp-list-action {
  padding-right: 0%;
}

.container {
  max-width: 1170px;
  padding: 0 15px;
  margin-right: auto;
  margin-left: auto;
}

.row-1 {
  /* margin : 2%; */
  /* display: flex; */
  /* width: 45%; */

  /*new profile changes*/
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row-3 {
  width: 45%;
  float: right;
}

.Org-row {
  margin: 2%;
  display: flex;
  float: right;
  margin-right: 0px;
}

.col-fields {
  margin-right: 10px;
  width: 50%;
}

.text-input {
  padding: 20px;
  width: 100%;
  border-radius: 5px;
  border: 0px;
}

.text-input-user-custom {
  padding: 15px;
  width: 100%;
  border-radius: 5px;
  /* border: 2px; */
}

.body-color {
  background: #f8fbff;
  overflow-x: hidden;
}

.text-select {
  width: 100%;
  border-radius: 5px;
  border: 0px;
}

.error-msg-notification {
  color: red;
  display: flex;
}

#address-error-msg-notification {
  display: flex;
  margin-left: 20px;
  color: red;
  margin-top: -14px;
}

.ant-modal-body {
  background: #f8fbff;
}

.ant-modal {
  pointer-events: all !important;
}

/* .ant-modal-title {
  margin-left: -76px;
} */

.config-modal {
  background: #f8fbff;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  height: 500px;
  width: 1200px !important;
}

.config-modal .ant-modal-content .ant-modal-body {
  overflow: hidden;
  width: 1200px;
}

.ant-modal-close-x {
  display: none;
}

.modal-header-config {
  margin-left: 500px;
}

.config-modal-header {
  width: 120px !important;
}

.ant-modal-header {
  /* margin-left: 65px ; */
  text-align: center;
}

.config-title {
  margin-left: 66px;
}

.config-modal-header {
  margin-left: 90px !important;
}

.code {
  display: flex !important;
  margin-left: 66px;
}

.spec {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 66px;
}

.partcode {
  display: flex;
  margin-left: 66px;
}

.feature {
  display: flex;
  padding-bottom: 20px;
  margin-left: 66px;
}

.whitelabel-part1 {
  margin-left: 66px;
}

.whitelabel-part2 {
  margin-left: 66px;
}

.conf-class {
  margin-left: 66px;
}

.modifier-class {
  margin-left: 66px;
}

.media-class {
  margin-left: 66px;
}

.ant-modal-body-footer {
  position: static;
}

.config-body-footer {
  width: 1200px !important;
}

#ant-modal-wrap {
  overflow: hidden;
}

.sign-out {
  margin: 6% 2% 2% 2%;
  text-align: right;
}

.text-input {
  padding: 20px;
  width: 100%;
  border-radius: 5px;
  border: 0px;
}

.body-color {
  background: #f8fbff;
  overflow-x: hidden;
}

.text-select {
  width: 100%;
  border-radius: 5px;
  border: 0px;
}

.sign-out {
  margin: 6% 2% 2% 2%;
  text-align: right;
}

.button-padding {
  margin: 4% 2% 17.5% 2%;
  display: contents;
}

.btn-type {
  margin: 0 2% 0 10px;
  border-radius: 13px;
  font-weight: 900;
  background-color: #0052a1;
  color: white;
  font-size: 16px;
  padding: 10px 20px 32px 20px;
}

.adduser-btn {
  margin: 0 2% 0 10px;
  border-radius: 13px;
  font-weight: 700;
  background-color: #0052a1;
  color: white;
  font-size: 16px;
  padding: 10px 20px 32px 20px;
}

#sign-out {
  margin: 0 2% 0 10px;
  border-radius: 13px;
  font-weight: 900;
  background-color: #0052a1;
  color: white;
  font-size: 16px;
  padding: 10px 20px 32px 20px;
}

#sign-out:hover {
  background-color: white;
  color: #0052a1;
}

.back-btn {
  margin: 0 2% 0 10px;
  border-radius: 13px;
  font-weight: 900;
  background-color: #0052a1;
  color: white;
  font-size: 16px;
  padding: 10px 20px 32px 20px;
}

.ant-select-selector {
  padding: 15px !important;
  height: 100% !important;
  border: 0px !important;
  border-radius: 5px !important;
}

.quantity::-webkit-inner-spin-button,
.quantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Add Doctor Ends  */
.tracks-admin {
  width: 1000px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 80px;
}

.buttonOutline {
  background-color: #0052a1 !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  color: white !important;
  border-radius: 13px !important;
}

.buttonOutline:hover {
  background-color: white !important;
  color: skyblue !important;
}

.company-card-admin {
  height: auto;
  /*170px*/
  cursor: pointer;
  margin: 10px;
  -webkit-transition: all 0.2s ease-in;
  -webkit-transform: scale(1);
  -ms-transition: all 0.2s ease-in;
  -ms-transform: scale(1);
  -moz-transition: all 0.2s ease-in;
  -moz-transform: scale(1);
  transition: all 0.2s ease-in;
  transform: scale(1);
  /*background      : #f8fbff;*/
  background: white;
  border-radius: 15px;
}

.ant-col-6 {
  display: block;
  box-sizing: border-box;
  width: calc(25% - 20px) !important;
}

.resp-card {
  padding: 8px;
  cursor: default;
}

.title-company {
  font-size: 16px;
  color: #5e5e5e;
  font-weight: 600;
  /* float   : right; */
  /* width   : calc(82% - 19px);
  margin-left: 19px; */
}

.title-span {
  height: 25px;
  /*overflow: hidden;*/
  font-size: 14px;
  color: black;
  padding: 15px;
}

.description_admin {
  /* margin-left: 16px; */
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  justify-content: center;
  font-size: 12px;
  font-weight: 100;
  color: #9c9c9c;
  padding-top: 14px;
}

.doc-option-admin {
  text-align: bottom;
  margin-left: 1%;
  float: right;
  margin-top: -250px;
}

.option-admin {
  text-align: bottom;
  margin-left: 1%;
}

/*written by me */
.detail {
  border: none;
  outline: 0;
  display: inline-block;
  color: white;
  background-color: #f7941d;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  opacity: 0.73;
}

.view_detail {
  padding-top: 40px;
}

/***/
.row1,
.row2 {
  display: flex;
}

.edit {
  border-radius: 3px;
  background-color: #eceff3;
  padding: 2px 7px;
  margin-right: 6px !important;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  cursor: pointer;
  color: #278bff;
}

.delete {
  border-radius: 3px;
  padding: 2px 7px;
  color: #fff;
  margin-right: 6px !important;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background-color: #278bff;
  cursor: pointer;
}

.company-card-admin.hover,
.company-card-admin:hover {
  cursor: pointer;
  z-index: 2;
  -webkit-transition: all 0.2s ease-in;
  -webkit-transform: scale(1.1);
  -ms-transition: all 0.2s ease-in;
  -ms-transform: scale(1.1);
  -moz-transition: all 0.2s ease-in;
  -moz-transform: scale(1.1);
  transition: all 0.2s ease-in;
  transform: scale(1.1);
  background: #fff;
  border: 1px solid rgba(95, 93, 93, 0.24);
}

.title-span-name {
  font-size: 12px;
  padding: 10px 0 0 0;
  text-transform: uppercase;
  color: #2d64ad;
}

.search-doctor {
  width: 40%;
  margin-left: 224px;
}

.star {
  width: 5%;
  float: left;
  padding-top: 70px;
}

.rating {
  float: left;
  padding-top: 67px;
  font-size: 12px;
  color: #f7941d;
}

p {
  font-weight: bold;
}

.whitelbl {
  width: 16%;
  margin-right: 15px;
  border: 1px solid lightskyblue;
  border-radius: 5px;
  padding: 5px;
}

.label-input {
  width: 95%;
  margin-right: 15px;
  border: 1px solid lightskyblue;
  border-radius: 5px;
  padding: 5px;
}

.label-class {
  margin-left: 66px;
  padding-right: 40px;
  display: flex;
  flex-direction: row;
}

.labelone {
  margin-left: 66px;
}

.lbl {
  width: 18%;
  margin-right: 15px;
  border: 1px solid lightskyblue;
  border-radius: 5px;
  padding: 5px;
}

.lblEmail {
  width: 40%;
  margin-right: 15px;
  border: 1px solid lightskyblue;
  border-radius: 5px;
  padding: 5px;
}

.Modlbl {
  width: 8%;
  margin-right: 15px;
  border: 1px solid lightskyblue;
  border-radius: 5px;
  padding: 5px;
}

.ant-radio-group {
  padding-bottom: 10px !important;
}

.ant-input-group {
  padding-bottom: 15px !important;
  font-size: 12px !important;
  padding-right: 20px !important;
}

.partcode {
  display: flex;
}

.code {
  display: flex !important;
}

.feature {
  display: flex;
  padding-bottom: 20px;
}

.ant-checkbox+span {
  font-size: 12px;
}

.modal-scrollbar {
  overflow-y: scroll;
}

.spec {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/*aside {
  float: left;
  padding-top: 50px;
}
*/
.ant-input-search,
.ant-input-affix-wrapper {
  border-radius: 14px;
}

.ant-input-affix-wrapper>input.ant-input {
  font-size: 14px;
}

.ant-menu-horizontal {
  margin-top: 60px;
  margin-left: -82%;
  border-radius: 15px;
  line-height: 35px !important;
}

.ant-menu-item {
  text-transform: uppercase;
  font-size: 16px;
  text-decoration: none;
}

/* .ant-menu-item-activ {
  background: blue !important;
  color: white !important;
  border-radius: 10px;
} */

.layers {
  width: 40%;
  margin-top: 10px;
  margin-left: -25px;
  border: 1px solid #9c9c9c1f;
  border-radius: 15px;
  box-shadow: 3px 3px 2px #9c9c9c;
}

.add-btn {
  border-radius: 13px;
  box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.03);
  width: auto;
  padding: 10px 70px 32px 70px;
  font-weight: 900;
  background-color: #0052a1;
  color: white;
  font-size: 16px;
}

.search-org {
  width: 55%;
  margin-bottom: -60px;
  padding-top: 40px;
  padding-left: 9px;
}

.chatitem1_name {
  font-size: 12px !important;
  margin-left: 20px !important;
  margin-top: -19px;
}

.code_name {
  font-size: 12px !important;
  margin-left: 20px !important;
  margin-top: -19px;
}

.ant-checkbox .ant-checkbox-checked {
  margin-bottom: -41px !important;
  background-color: white;
}

.ant-modal-content {
  width: 700px;
  height: min-content;
  margin-left: -80px;
  overflow: none;
}

.modal-class {
  width: 1200px;
  height: min-content;
  margin-left: -80px;
}

/* .ant-modal-body {
  height: min-content;
  overflow: auto !important;
} */
.ant-list-item-meta-content {
  margin-bottom: -28px !important;
}

.ant-list-item-meta-title {
  margin-left: -3px !important;
  margin-top: -23px !important;
}

.SpecHead {
  font-size: 16px;
  font-weight: bold;
  color: black;
  padding-bottom: 15px;
}

.config-btn {
  margin: 0 2% 0 10px;
  border-radius: 13px;
  font-weight: 900;
  background-color: #0052a1;
  color: white;
  font-size: 16px;
  padding: 10px 20px 32px 20px;
  /* margin-left  : -71px; */
}

.config-btn:hover {
  background-color: white;
  color: #0052a1;
}

.display {
  display: flex;
  flex-direction: column;
}

.ant-form-item-label>label {
  float: left;
}

.table-doctor {
  width: 173px;
  /* height      : 80px; */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: start;
}

#table-name {
  padding: 7px;
}

#table-action {
  display: -webkit-inline-box;
}

#table-edit {
  color: #1890ff;
}

#table-edit:hover {
  color: red;
}

#table-delete {
  color: #1890ff;
  margin-left: 16px !important;
  cursor: pointer;
}

#table-delete:hover {
  color: red !important;
}

.addspec-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#sub-row {
  display: block;
}

@media (max-width: 840px) {
  .title-span {
    font-size: 12px;
  }

  .title-span-name {
    font-size: 11px;
    overflow-wrap: break-word;
  }

  .description_admin {
    padding-top: 6px;
  }

  .detail {
    font-size: 14px;
  }

  .search-doctor {
    width: 150px;
    margin-left: 38px;
  }
}

/* Upload profile styles */

/* .profile-card{
  display: flex;
  flex-direction: row;
  cursor: pointer;
} */
.image-style {
  height: 101px;
  width: 100px;
  border-radius: 10%;
  cursor: pointer;
}

.image-style-user-custom {
  height: 81px;
  width: 80px;
  border-radius: 50%;
  cursor: pointer;
}

#photo-upload {
  display: none;
}

.filter-btn {
  margin: -55px 2% 0 120%;
  border-radius: 13px;
  font-weight: 900;
  background-color: #0052a1;
  color: white;
  font-size: 16px;
  padding: 10px 20px 32px 20px;
}

.ant-table table {
  font-size: 15px;
}

.notification-col{
  display: flex;
  justify-content: left;
  align-items: center;
}

.notification-settings{
  font-size: 16px;
}
.notification-settings.ant-checkbox-inner span{
  width: 24px;
  height: 24px;
}

.org-select div span{
  align-content: center;
}