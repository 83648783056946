
.closecodeheaderMenu {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: none;
    margin: 0;
    padding: 0;
    list-style: none;
}

.closecodeheaderMenu>li {
    float: left;
    margin: 12px 0;
    color: rgba(0, 0, 0, .45);
}

.closecodeheaderMenu>li:not(:last-child) {
    border-right: none
}

li.bellNotification {
    display: flex;
    align-self: center;
}

.headerPorifileDiv {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.buttonOutline {
    color: #4872b6;
    border-color: #0252a1;
}

.headerSubtitle {
    font-size: 17px;
    font-weight: 500;
}

.proifle_name {
    font-size: 14px;
    font-weight: 400;
    color: black;
    margin-left: 10px;
}

.chatItem_avatar {
    border-radius: 30%;
    margin-right: 10px;
}

.flexcenter {
    display: flex;
    align-items: center;
}

.search-close-code {
        width: 70%;
        margin-bottom: -60px;
        padding-top: 30px;
        padding-left: 9px;
}

.add-btn {
    border-radius: 13px;
    box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.03);
    width: auto;
    padding: 10px 70px 32px 70px;
    font-weight: 900;
    background-color: #0052a1;
    color: white;
    font-size: 16px;
}

.permission-row {
    margin: 2%;
    display:flex;
    float: right;
    margin-right: 0px;
}

#description1 {
    margin-top: 15px;
}