.sub-bar {
  display: flex;
  flex-direction: row;
  padding: 15px;
  width: 100%;
}

.addconsumer-btn {
  display: flex;
  flex-direction: row;
  margin: -44% 0% 50px 170%;
  border-radius: 13px;
  font-weight: 900;
  background-color: #0052a1;
  color: white;
  font-size: 16px;
  padding: 10px 20px 32px 20px;
}

.addConsumerButton {
  margin: 0 2% 0 10px;
  border-radius: 13px;
  font-weight: 900;
  background-color: #0052a1;
  color: white;
  font-size: 16px;
  padding: 10px 20px 32px 20px;
}

.add-btn {
  display: flex;
  flex-direction: row;
  margin: 0% 2% 50px 275%;
  border-radius: 13px;
  font-weight: 900;
  background-color: #0052a1;
  color: white;
  font-size: 16px;
  padding: 10px 20px 32px 20px;
}

.bulk-btn {
  display: flex;
  flex-direction: row;
  margin: -31% 0% 50px 230%;
  border-radius: 13px;
  font-weight: 900;
  background-color: #0052a1;
  color: white;
  font-size: 16px;
  padding: 10px 20px 32px 20px;
}

.temp-btn {
  display: flex;
  flex-direction: row;
  margin: -31% 0% 50px 285%;
  border-radius: 13px;
  font-weight: 900;
  background-color: #0052a1;
  color: white;
  font-size: 16px;
  padding: 10px 20px 32px 20px;
}

.display {
  display: flex;
  flex-direction: column;
}

.ant-form-item-label > label {
  float: left;
}

.btn-type1 {
  margin: -91px 2% 0 120%;
  border-radius: 13px;
  font-weight: 900;
  background-color: #0052a1;
  color: white;
  font-size: 16px;
  padding: 10px 20px 32px 20px;
}
.view-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.buttons {
  display: flex;
  flex-direction: row;
  border-radius: 13px;
  font-weight: 900;
  background-color: #0052a1;
  font-size: 16px;
  padding: 10px 20px 32px 20px;
}

@media (max-width: 1450px) {
  .addconsumer-btn {
    margin: -40% 0% 50px 275%;
  }
}

@media (min-width: 1670px) {
  .addconsumer-btn {
    margin: -31% 0% 50px 170%;
  }
  .bulk-btn {
    margin: -23% 0% 50px 230%;
  }
  .temp-btn {
    margin: -22% 0% 50px 285%;
  }
}

@media (max-width: 1300px) {
  .addconsumer-btn {
    margin: -44% 0% 50px 170%;
  }
}

@media (min-width: 1700px) {
  .addconsumer-btn {
    margin: -30% 0% 50px 275%;
  }
}

@media (min-width: 1900px) {
  .addconsumer-btn {
    margin: -19% 0% 50px 150%;
  }
  .bulk-btn {
    margin: -14% 0% 50px 184%;
  }
  .temp-btn {
    margin: -14% 0% 50px 216%;
  }
}
