.sub-bar{
    display: flex;
    flex-direction: row;
    padding: 15px;
    width: 100%;
}

.addadmin-btn {
    margin: 0 2% 0 10px;
    border-radius: 13px;
    font-weight: 900;
    background-color: #0052a1;
    color: white;
    font-size: 16px;
    padding: 10px 20px 32px 20px;
  }

  .display {
      display: flex;
      flex-direction: column;
  }

  .ant-form-item-label > label {
    float: left;
  }

  .table-doctor {
    width          : 173px;
    /* height      : 80px; */
    text-align     : center;
    display        : flex;
    flex-direction : row;
    justify-content: start;
    cursor: pointer;
  }