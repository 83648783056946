.sub-bar {
  display: flex;
  flex-direction: row;
  padding: 15px;
  width: 100%;
}
.sub-bar-other {
  display: flex;
  flex-direction: row;
  padding: 15px;
  width: 100%;
  padding-left: 0px;
  /* padding */
}
.addadmin-btn {
  margin: 0 2% 0 10px;
  border-radius: 13px;
  font-weight: 900;
  background-color: #0052a1;
  color: white;
  font-size: 16px;
  padding: 10px 20px 32px 20px;
}

.display {
  display: flex;
  flex-direction: column;
}

.ant-form-item-label > label {
  float: left;
}
