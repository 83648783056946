.sub-bar{
    display: flex;
    flex-direction: row;
    padding: 15px;
    width: 100%;
}

.addadmin-btn {
    margin: 0 2% 0 10px;
    border-radius: 13px;
    font-weight: 900;
    background-color: #0052a1;
    color: white;
    font-size: 16px;
    padding: 10px 20px 32px 20px;
  }

  .display {
      display: flex;
      flex-direction: column;
  }

  .ant-form-item-label > label {
    float: left;
  }

  .footer-btn {
    text-align: center;
    text-transform: uppercase;
    color: #fff !important;
    background: #2D64AF;
    border-radius: 10px;
    height: 100%;
    /* width: 88%; */
    padding: 2% 5%;
    cursor: pointer;
    display: inline-block;
   
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* .create-modal{
    height: 350px;
  } */
